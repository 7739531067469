import React from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';

const Link = props => (
  <TransitionLink exit={{ length: 0.5 }} {...props}>
    {props.children}
  </TransitionLink>
);

export default Link;
