import React from 'react';
import styles from './styles.module.scss';
import { PATHS } from 'constants/paths';
import Link from 'components/Link';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

const Header = () => {
  let topDir;

  if (typeof window !== `undefined`) {
    const pathname = window.location.pathname;
    topDir = pathname.replace(/^\//, '').split('/')[0];
  }

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      file(relativePath: { eq: "logo-color.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(height: 190) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <header className={styles.headerRoot}>
      <div className={styles.logoWrapper}>
        <Link to='/home'>
          <Img alt={'logo'} fixed={data.file.childImageSharp.fixed} loading='eager' />
        </Link>
      </div>
      <div className={styles.navRoot}>
        {PATHS.map(({ display, path }, i) => {
          const classNames = [styles.navSection];
          path.replace('/', '') === topDir && classNames.push(styles.navSectionActive);
          return (
            <Link to={path} key={`path-${i}`} className={classNames.join(' ')}>
              {display}
            </Link>
          );
        })}
      </div>
    </header>
  );
};

export default Header;
