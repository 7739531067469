/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { Helmet as ReactHelmet } from 'react-helmet';

function Helmet({ description, title, type }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            description
            author
          }
        }
      }
    `
  );

  const { siteUrl, author, title: siteTitle } = site.siteMetadata,
    shareImageUrl = `${siteUrl.replace(/\/$/, '')}/tiny-muscle-athlete.jpg`,
    metaDescription = description || site.siteMetadata.description,
    pageTitle = title ? `${title} | ${siteTitle}` : siteTitle;

  return (
    <ReactHelmet>
      <title>{pageTitle}</title>
      <meta property='og:title' content={pageTitle} />
      <meta property='og:type' content={type} />
      <meta property='og:url' content={siteUrl} />
      <meta property='og:image' content={shareImageUrl} />
      <meta property='og:description' content={metaDescription} />
      <meta name='description' content={metaDescription} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:creator' content={author} />
      <meta name='twitter:description' content={metaDescription} />
    </ReactHelmet>
  );
}

Helmet.defaultProps = {
  meta: [],
  description: ``,
  type: `website`
};

export default Helmet;
