import { Transition } from 'react-spring/renderprops-universal';
import { TransitionState } from 'gatsby-plugin-transition-link';
import React from 'react';

const TmaTransition = ({ from, enter, leave, className, children }) => (
  <TransitionState>
    {({ transitionStatus }) => {
      return (
        <Transition items={['entering', 'entered'].includes(transitionStatus)} from={from} enter={enter} leave={leave}>
          {show =>
            show &&
            (props => (
              <div className={className} style={{ ...props, position: 'relative' }}>
                {children}
              </div>
            ))
          }
        </Transition>
      );
    }}
  </TransitionState>
);

export const SlideDown = ({ className, children }) => (
  <TmaTransition
    from={{ transform: 'translateY(-100%)' }}
    enter={{ transform: 'translateY(0)' }}
    leave={{ transform: 'translateY(100%)' }}
    className={className}
  >
    {children}
  </TmaTransition>
);

export const SlideDownThenUp = ({ className, children }) => (
  <TmaTransition
    from={{ transform: 'translateY(-100%)' }}
    enter={{ transform: 'translateY(0)' }}
    leave={{ transform: 'translateY(-100%)' }}
    className={className}
  >
    {children}
  </TmaTransition>
);

export const SlideUp = ({ className, children }) => (
  <TmaTransition
    from={{ transform: 'translateY(100%)' }}
    enter={{ transform: 'translateY(0)' }}
    leave={{ transform: 'translateY(-100%)' }}
    className={className}
  >
    {children}
  </TmaTransition>
);

export const SlideUpThenDown = ({ className, children }) => (
  <TmaTransition
    from={{ transform: 'translateY(100%)' }}
    enter={{ transform: 'translateY(0)' }}
    leave={{ transform: 'translateY(100%)' }}
    className={className}
  >
    {children}
  </TmaTransition>
);
