import React from 'react';
import styles from './styles.module.scss';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import { PATHS } from '../../constants/paths';
import Link from '../Link';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      file(relativePath: { eq: "logo-black-image-only.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerContentLeft}>
          <Img alt={'footer'} fixed={data.file.childImageSharp.fixed} loading='eager' />
        </div>
        <div className={styles.footerContentRight}>
          {PATHS.map(({ display, path }, i) => {
            return (
              <Link to={path} key={`path-${i}`} className={styles.footerNavSection}>
                {display}
              </Link>
            );
          })}
        </div>
      </div>
      <div className={styles.footerCopyright}>© Copyright Tiny Muscle Athlete 2019</div>
    </footer>
  );
};

export default Footer;
